.control-button {
  width: 35px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  // padding-left: 4px;
  // padding-right: 2px;
  // padding-top: 2px;
  // padding-bottom: 2px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    background-color: rgba(79, 125, 158, .5);
  }

  &:active {
    background-color: rgba(79, 125, 158, .5);
  }
}