.widget-count_container {
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-content: center;
}

.widget-count__content {
  align-self: center;

  &:hover {
    cursor: pointer;
  }

  &--value {
    text-align: center;
    font-size: 38px;
    font-size: 4vh;
  }

  &--label {
    text-align: center;
  }
}
