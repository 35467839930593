:root {

  .rct-tree-item-li  {
    font-size: 1rem;
    box-sizing: border-box;
  }

  .rct-tree-item-arrow {
  }

  --rct-color-tree-bg: transparent;
  --rct-color-tree-focus-outline: transparent;

  --rct-color-focustree-item-selected-bg: transparent;
  --rct-color-focustree-item-selected-text: inherit;
  --rct-color-focustree-item-focused-border: #141416;
  --rct-color-focustree-item-draggingover-bg: #141416;
  --rct-color-focustree-item-draggingover-color: inherit;

  --rct-color-nonfocustree-item-selected-bg: #141416;
  --rct-color-nonfocustree-item-selected-text: inherit;
  --rct-color-nonfocustree-item-focused-border: transparent;

  --rct-color-search-highlight-bg: transparent;
  --rct-color-drag-between-line-bg: #141416;
  --rct-color-arrow: white;

  --rct-item-height: 22px;

  --rct-color-renaming-input-submitbutton-bg: inherit;
  --rct-color-renaming-input-submitbutton-bg-hover: transparent;
  --rct-color-renaming-input-submitbutton-bg-active: transparent;

  --rct-color-renaming-input-submitbutton-text: inherit;
  --rct-color-renaming-input-submitbutton-text-hover: #ffffff;
  --rct-color-renaming-input-submitbutton-text-active: #ffffff;
}
