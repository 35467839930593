.popup {
  .popup-inner {
    width: 100%;
    padding-bottom: 18px;
    padding-top: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgba(27, 28, 30, 0.75);
    border: 2px solid #737373;
    color: white;
    clip-path: polygon(
      0 100%,
      0 0,
      calc(50% - 11px) 0%,
      calc(50% - 11px) 2px,
      calc(50% + 11px) 2px,
      calc(50% + 11px) 0%,
      100% 0,
      100% 100%
    );
    overflow: hidden;
    font-size: 14px;
    &:dir(rtl) {
      padding-right: 18px;
    }
    &:dir(ltr) {
      padding-left: 18px;
    }
    div div h3 {
      margin: 0;
      margin-bottom: 10px;
    }

    .grid-container {
      width: 100%;
      display: inline-grid;
      column-gap: 10px;
      row-gap: 5px;
      grid-template-columns: auto auto;
      align-items: start;
      justify-content: flex-start;

      div {
        min-width: 0;
        min-height: 0;
        word-wrap: break-word;
      }
    }
  }

  &--panic {
    .popup-inner {
      border-color: rgba(255, 91, 91, 1);
    }
    &:before {
      border-color: rgba(255, 91, 91, 1) !important;
      border-top-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    border: 2px solid #737373;
    height: 20px;
    width: 20px;
    bottom: calc(100% - 12.2px);
    left: calc(50% - 10px);
    background-image: linear-gradient(
      -45deg,
      rgba(27, 28, 30, 0.75) 50%,
      transparent 50%
    );
    background-repeat: no-repeat;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(-135deg);
    z-index: -1;
  }
}

.mapboxgl-popup-close-button {
  all: unset;
  cursor: pointer;
  font-size: 30px !important;
  color: rgba(255, 255, 255, 0.9);
  -webkit-transform: translate(0);
  transform: translate(0);
  position: absolute;
  top: 3px !important;
  &:dir(rtl) {
    left: 3px !important;
    margin-right: 4px;
  }
  &:dir(ltr) {
    right: 3px !important;
    margin-left: 4px;
  }
  box-sizing: border-box;
  padding: 6px;
}

.mapboxgl-popup-close-button:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
  color: rgba(255, 255, 255, 0.9) !important;
  opacity: 1 !important;
}
