.recharts-pie {
  &:focus,
  *:focus {
    outline: none !important;
  }
}

.recharts-surface {
  &:focus {
    outline: none !important;
  }
}
