html,
body,
#root {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #3f4349;
}

@media only screen and (max-width: 768px) {
  html,
  body,
  #root {
    min-height: -webkit-fill-available;
  }
  html {
    overflow: hidden;
    height: 100dvh;
    width: 100vw;
  }
  body {
    overflow: auto;
    height: 100%;
    width: 100%;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s;
  // -webkit-text-fill-color: white !important;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.flex {
  display: flex;
  flex: 1;
  &-1 {
    flex: 1;
  }
  &-col {
    flex-direction: row;
  }
}

.hidden {
  display: none;
}
.gauge-container > .gauge .dial {
  stroke: #334455;
  stroke-width: 7;
  stroke-dasharray: 20 1;
}
.gauge-container > .gauge .value {
  stroke-dasharray: 20 1;
  stroke-width: 7;
}
.gauge-container > .gauge .value-text {
  font-size: 1em;
}

.gauge-container > .gauge .value-text::after {
  content: '%';
  display: inline-block;
  width: 12px;
  height: 12px;
}

.hoverable-link {
  color: inherit;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hoverable-link:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.minfarm-watermarked {
  position: relative;
  width: 100%;
  height: 100%;
}

.minfarm-watermarked:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url('/assets/minfarm-watermark-color.png');
  background-size: 20% auto;
  background-position: 5% 95%;
  background-repeat: no-repeat;
  opacity: 0.7;
}

.custom-tab-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
}

/* Flip pagination arrows in RTL only */
[dir='rtl'] .MuiDataGrid-footerContainer [data-testid='KeyboardArrowLeftIcon'],
[dir='rtl']
  .MuiDataGrid-footerContainer
  [data-testid='KeyboardArrowRightIcon'] {
  transform: scaleX(-1);
}

[dir='rtl'] .MuiInputLabel-root {
  left: inherit !important;
  right: 1.75rem !important;
  transform-origin: right !important;
}
[dir='rtl'] .MuiOutlinedInput-root fieldset {
  text-align: right;
}

[dir='rtl'] .MuiInputBase-adornedEnd {
  padding-left: 39px !important;
  padding-right: 6px !important;
}

[dir='rtl'] .MuiAutocomplete-endAdornment {
  right: inherit !important;
  left: 9px !important;
}
