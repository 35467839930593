.mapboxgl-control-custom-controls {
  all: unset;
  cursor: pointer;
  position: absolute;
  display: flex;
  bottom: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  flex-direction: column;
  pointer-events: all;
  box-sizing: border-box;

  &:hover {
    outline: rgba(79, 125, 158, 1) solid 1px;
  }

  div:first-child div {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-top: 4px;
  }

  div:last-child div {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.right {
  left: auto;
  right: 80px !important;
}

.left {
  right: auto;
  left: 15px !important;
}

.mapboxgl-controls-custom-divider {
  width: 90%;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
  margin-bottom: 4px;
}

#control-style-selector-menu {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 6px;
  width: 120px;

  .control-style-selector-item-active {
    background: rgba(79, 125, 158, 1);
  }

  div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    flex-direction: column;

    button {
      // OVERRIDE START
      font-family: inherit; /* 1 */
      font-size: 100%; /* 1 */
      line-height: 1.15; /* 1 */
      margin: 0; /* 2 */

      overflow: visible;

      text-transform: none;

      -webkit-appearance: button;

      background-color: transparent;
      border: none;
      outline: none;
      // OVERRIDE END

      flex-basis: 50%;
      padding: 6px;
      color: white;
      cursor: pointer;

      &:hover {
        background: rgba(79, 125, 158, 1);
      }
    }
  }

  div:first-child button {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  div:last-child button {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.control-hidden {
  display: none;
}
