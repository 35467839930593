.options-button {
  font-size: 12px;
  background-color: rgba(51, 60, 67, 0.65);
  border-radius: 4px;
  padding: 2px 6px;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;

  &:hover {
    background-color: rgba(51, 60, 67, 0.95);
  }
}

.options-actions {
  font-size: 14px;
  position: absolute;
  border-radius: 4px;
  right: 0;
  // top: 64px;
  background-color: rgba(71, 84, 95, 0.95);
  cursor: pointer;
}

.options-action {
  display: flex;
  padding: 8px;
  padding-top: 10px;

  &:hover {
    background-color: rgba(51, 60, 67, 0.95);
  }
}

.disabled-option {
  cursor: not-allowed;
  background-color: rgba(71, 84, 95, 0.15);
  color: rgba(255, 255, 255, 0.3);
}

.clearing-status {
  font-size: 12px;
  background-color: rgba(51, 60, 67, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  align-items: end;
  display: flex;
  cursor: pointer;
}

.clearing-status .clearing-status-title {
  padding: 4px 4px 4px 6px;
}

.notification-slide-first {
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
}

.notification-slide {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
}

.notification-slide-in-first {
  animation: notification-slide-in-first 0.3s forwards;
  -webkit-animation: notification-slide-in-first 0.3s forwards;
}

.notification-slide-in {
  animation: notification-slide-in 0.3s forwards;
  -webkit-animation: notification-slide-in 0.3s forwards;
}

.notification-slide-out-first {
  animation: notification-slide-out-first 0.3s backwards;
  -webkit-animation: notification-slide-out-first 0.3s backwards;
}

.notification-slide-out {
  animation: notification-slide-out 0.3s backwards;
  -webkit-animation: notification-slide-out 0.3s backwards;
}

@keyframes notification-slide-in-first {
  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes notification-slide-in-first {
  100% {
    -webkit-transform: translateY(0px);
  }
}

@keyframes notification-slide-in {
  0% {
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes notification-slide-in {
  100% {
    -webkit-transform: translateY(0%);
  }
}

@keyframes notification-slide-out-first {
  0% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes notification-slide-out-first {
  0% {
    -webkit-transform: translateY(0px);
  }
}

@keyframes notification-slide-out {
  0% {
    transform: translateY(0%);
  }
  100% {
  }
}

@-webkit-keyframes notification-slide-out {
  0% {
    -webkit-transform: translateY(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-100%);
  }
}

.show-less-slide {
  padding: 6px;
}

.show-less-slide-in {
  animation: show-less-slide-in 0.3s forwards;
  -webkit-animation: show-less-slide-in 0.3s forwards;
}

@keyframes show-less-slide-in {
  0% {
    padding: 0;
    -webkit-transform: translateY(0%);
  }
  100% {
    padding: 6px;
    transform: translateY(0%);
  }
}

@-webkit-keyframes show-less-slide-in {
  0% {
    padding: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    padding: 6px;
    transform: translateY(0%);
  }
}
