.color-selector {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    padding: 10px 0; /* Adjust top and bottom padding */
}

.color-circle {
    --number-of-items: 6;
    --dist-between-circles: 24px; /* Adjust spacing between circles */
    flex: 0 0 auto;
    width: calc((100% - (var(--dist-between-circles) * var(--number-of-items))) / var(--number-of-items)); /* Distribute equally among 5 circles */
    padding-bottom: calc((100% - (var(--dist-between-circles) * var(--number-of-items))) / var(--number-of-items)); /* Maintain a perfect circle aspect ratio */
    margin-right: var(--dist-between-circles); /* Adjust spacing between circles */
    box-sizing: border-box;
    border-radius: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative; /* Add relative positioning */
    box-shadow: inset 0px 0px 2px rgba(0,0,0,0.9);
}

.selected {
    border: 2px solid white;
    padding-bottom: calc((100% - (var(--dist-between-circles) * var(--number-of-items))) / var(--number-of-items) - 4px);
}

.selected:after {
    font-size: 12px !important;
    line-height: 16px !important;
}

/* Add more specific styling for individual colors if needed */
.purple {
    background-color: #a29bfe;
}

.orange {
    background-color: #fab1a0;
}

.pink {
    background-color: #fd79a8;
}

.teal {
    background-color: #81ecec;
}

.yellow {
    background-color: #ffeaa7;
}

.transparent {
    background-color: #909090;
}

.transparent:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 0;
    content: "\2716"; /* use the hex value here... */
    font-weight: lighter;
    color: white;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
}
