.mapboxgl-canvas {
  outline: none !important;
}

.mapboxgl-control-container div .mapboxgl-ctrl-group.mapboxgl-ctrl .mapbox-gl-draw_ctrl-draw-btn {
  display: none;
}

.mapboxgl-popup-content {
  padding: 0;
  background: transparent;
  color: white;

  .mapboxgl-popup-close-button {
    font-size: 24px;
    top: 5px;
    &:dir(rtl) {
      left: 3px !important;
    }
    &:dir(ltr) {
      right: 3px !important;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

.mapboxgl-popup-anchor-top{
  margin-top: 20px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button {
  color: white;
}

.width-300px {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}

.width-360px {
  width: 360px;
  max-width: 360px;
  min-width: 360px;
}

.width-auto {
  width: auto;
}

.popup-hidden {
  display: none;
}

.all-unset {
  .mapboxgl-popup-content,
  .mapboxgl-popup-tip {
    all: unset;
  }
}

.context-menu {
  .mapboxgl-popup-tip {
    display: none;
    border-radius: none;
  }

  .mapboxgl-popup-content {
    padding: 0;
  }
}

.mapboxgl-ctrl-bottom-left {
  opacity: 0.5;
}

.mapboxgl-ctrl-bottom-right {
  opacity: 0.5;
}

.mapboxgl-ctrl-attrib {
  opacity: 0.5;
  padding: 0;
  // background: transparent;

  &.mapboxgl-compact {
    padding: 0;
    // background: transparent;
  }
  &.mapboxgl-compact-show {
    .mapboxgl-ctrl-attrib-button {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.map-container-left-margin {
  .mapboxgl-ctrl-bottom-left {
    left: 72px; // TODO: change if RTL
    bottom: 4px;
  }
}

.map-container-right-margin {
  .mapboxgl-ctrl-bottom-right {
    right: 72px; // TODO: change if RTL
    bottom: 4px;
  }
}

// .mapboxgl-ctrl-scale {
//   margin-left: 92px !important;
//   margin-bottom: -4px !important;
// }

.mapboxgl-ctrl-geocoder {
  left: 64px;
}


@media only screen and (max-width: 768px) {
  .mapboxgl-ctrl-bottom-left {
    position: absolute;
    left: 5px !important;
    bottom: 55px !important;
  }
}
