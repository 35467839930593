.widget-container {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  h3 {
    height: 60px;
  }
}

.widget-content {
  height: calc(100% - 60px);
  padding: 16px;
}

.dashboard-widget-drag-handle {
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 16px;
  box-sizing: border-box;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
}
