.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #131415;
  border-radius: 8px;
  border: 4px solid transparent;
}

.custom-scrollbar::-webkit-scrollbar-button {
  display:none;
}